<template>
    <div class="taskReview">
        <header>
            <div class="tabs">
                <el-tabs v-model="mainTab" @tab-click="mainTabClick" class="main-tab">
                    <el-tab-pane v-if="subMenu.toBeReviewed" label="待审核" name="1"></el-tab-pane>
                    <el-tab-pane v-if="subMenu.reviewOfMajorTasks" label="已审核重大任务" name="2"></el-tab-pane>
                    <el-tab-pane v-if="subMenu.reviewIndicators" label="已审核指标" name="3"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="searchs">
                <el-form :inline="true" :model="query" class="demo-form-inline">
                    <el-form-item label="重大任务名称：" prop="actionName">
                        <el-input placeholder="请输入" v-model="query.actionName"></el-input>
                    </el-form-item>
                    <el-form-item label="牵头单位：" v-if="mainTab == 1">
                        <el-cascader
                            @change="handleChange"
                            ref="cascader"
                            filterable
                            clearable
                            v-model="actionDeptName"
                            :props="defaultProps"
                            :key="query.actionDeptId"
                        />
                    </el-form-item>
                    <el-form-item label="年度：" v-if="mainTab == 2">
                        <el-date-picker
                            v-model="query.actionYear"
                            type="year"
                            placeholder="请选择"
                            value-format="YYYY"
                        />
                    </el-form-item>
                    <el-form-item label="上报人：" v-if="mainTab == 3">
                        <el-input placeholder="请输入" v-model="query.createName"></el-input>
                    </el-form-item>
                </el-form>
                <div class="btns">
                    <el-button @click="search" type="primary">查 询</el-button>
                    <el-button @click="resize" plain>重 置</el-button>
                </div>
            </div>
        </header>
        <main>
            <div class="subToolbar">
                <div class="title" v-if="mainTab == '1'">待审核列表</div>
                <div class="title" v-if="mainTab == '2'">已审核重大任务列表</div>
                <div class="title" v-if="mainTab == '3'">已审核指标列表</div>
                <el-button v-if="mainTab === '2' && userBtns.reviewofmajortasks_export" @click="exportFile" type="primary"
                    >导 出</el-button
                >
                <el-button v-if="mainTab === '3' && userBtns.reviewIndicators_export" @click="exportFile" type="primary"
                    >导 出</el-button
                >
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    @selection-change="handleSelectionChange"
                    @cell-dblclick="dblclickRow"
                >
                    <el-table-column type="selection" width="40" />
                    <el-table-column type="index" width="70" label="序号" class-name="el-table-fixed-column--left" />
                    <el-table-column
                        v-for="item in tableColumns"
                        :prop="item.prop"
                        :label="item.label"
                        :width="item.width"
                        :key="item.prop"
                        :formatter="item.formatter"
                        :show-overflow-tooltip="true"
                        :class-name="item.class"
                    >
                    </el-table-column>
                    <el-table-column prop="verifyType" label="审核意见" v-if="mainTab == '3'">
                        <template #default="{ row }">
                            <div>
                                <span
                                    :class="['status', 'status' + row.verifyType]"
                                    v-if="row.verifyType == 0"
                                    slot="reference"
                                    >• 通过</span
                                >
                                <span
                                    :class="['status', 'status' + row.verifyType]"
                                    v-if="row.verifyType == 1"
                                    slot="reference"
                                    >• 不通过</span
                                >
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100" fixed="right" class-name="textCenter">
                        <template #default="scope" class="button">
                            <el-button
                                type="text"
                                size="small"
                                v-if="mainTab == '1' && userBtns.tobereviewed_review"
                                @click="audit(scope.row)"
                                >审核</el-button
                            >
                            <el-button
                                type="text"
                                size="small"
                                v-if="mainTab == '2' && userBtns.reviewofmajortasks_check"
                                @click="openDetail(scope.row)"
                                >查看</el-button
                            >
                            <el-button
                                type="text"
                                size="small"
                                v-if="mainTab == '3' && userBtns.reviewIndicators_check"
                                @click="openDetail(scope.row)"
                                >查看</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        v-model:currentPage="query.page"
                        v-model:page-size="query.size"
                        :page-sizes="[15, 30, 50, 100]"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="getList"
                        @current-change="getList"
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import auditConfig from '@/views/TaskReview/components/auditConfig';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    role.forEach((e) => {
        if (roles.indexOf(e) > -1) {
            res = false;
        }
        return res;
    });
    return res;
}
const router = useRouter();
const route = useRoute();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    page: 1,
    size: 15,
    getMyVerifiedAction: '',
    currentNodeId: '',
    actionName: '',
    indexName: '',
    getMyVerifiedIndex: '',
    actionDeptId: '',
});
let mainTab = ref('1');
let checkData = ref([]);
let total = ref(0);
let addData = ref({});
const checkOptions = [
    {
        value: '',
        label: '全部',
    },
    {
        value: 1,
        label: '通过',
    },
    {
        value: 2,
        label: '不通过',
    },
];
let actionDeptName = ref([]);
const defaultProps = {
    lazy: true,
    checkStrictly: true,
    async lazyLoad(node, resolve) {
        // console.log(node,);
        const { level } = node;
        let data = level > 0 ? node.value : 'main';
        level + 1;
        let res = await axios({
            url: '/front/sysOrganization/getByParentExternalId',
            method: 'get',
            params: {
                parentExternalId: data,
            },
        }); //.then(  => {
        if (res) {
            console.log(res);
            let nodes = res.map((item) => {
                return {
                    label: item.organizationName,
                    value: item.externalId,
                    leaf: false,
                    // deptId: item.deptId,
                };
            });
            if (res.length == 0) {
                node.data.leaf = true;
            }
            resolve(nodes);
        }
        // });
    },
};
const options = ref([]);
let tableData = ref([]);
let showFeedback = ref(false);
let titleName = ref('');
let infoForm = ref({});
const audit = (row) => {
    router.push({
        path: 'tastReviewDialog',
        query: {
            title: '审核',
            id: row.actionId,
        },
    });
};
let cascader = ref({});
// 点击联选节点触发事件
function handleChange(val) {
    let nodesInfo = cascader.value.getCheckedNodes()[0];
    console.log(nodesInfo);
    query.value.actionDeptId = nodesInfo.data.value;
}
function success() {
    showFeedback.value = false;
    getList();
}
function cancle() {
    showFeedback.value = false;
    getList();
}
function openDetail(row) {
    addData.value = JSON.parse(JSON.stringify(row));
    if (mainTab.value == 2) {
        router.push({
            path: 'tastReviewDialog',
            query: {
                title: '查看重大任务',
                id: row.actionId,
            },
        });
    } else {
        router.push({
            path: 'tastReviewDialog',
            query: {
                title: '查看指标',
                id: row.actionId,
            },
        });
    }
}
function dblclickRow(row) {
    if (mainTab.value == 1) {
        audit(row);
    } else {
        openDetail(row);
    }
}

function handleSelectionChange(val) {
    checkData.value = val;
    console.log(checkData.value);
}
async function getList() {
    query.value.getMyVerifiedAction = '';
    query.value.currentNodeId = '';
    query.value.getMyVerifiedIndex = '';
    if (mainTab.value == 2) {
        query.value.getMyVerifiedAction = 1;
    } else if (mainTab.value == 1) {
        query.value.currentNodeId = store.state.user.currentNodeId.join(',');
    } else if (mainTab.value == 3) {
        query.value.getMyVerifiedIndex = 1;
    }
    let url;
    let method;
    url = mainTab.value == 3 ? 'frontIndex' : 'frontAction';
    method = mainTab.value == 1 ? 'toBeReviewed' : 'reviewed';
    console.log(proxy.$api[url]);
    proxy.$api[url][method](query.value).then((res) => {
        if (res.code == 200) {
            tableData.value = res.data.records;
            total.value = res.data.total;
        }
    });
}

function exportFile() {
    let baseUrl = '/prod-api'
    let params = query.value
    let url = mainTab.value == '2' ? '/front/frontAction/export' : '/front/frontIndex/export'
    let title = mainTab.value == '2' ? '已审核重大任务.xlsx' : '已审核指标.xlsx'
    let paramsArray = [];
    if(mainTab.value == '2'){
        params.actionIds = checkData.value.map(e => e.actionId)
    } else {
        params.indexIds = checkData.value.map(e => e.indexId)
    }
    //拼接参数
    Object.keys(params).forEach((key) => paramsArray.push(key + '=' + params[key]));
    if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&');
    } else {
        url += '&' + paramsArray.join('&');
    }
    fetch(baseUrl + url, {
        method: 'get',
        headers: {
            Authorization: localStorage.getItem('token'),
        },
    }).then((res) => {
        res.blob().then((blob) => {
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            var filename = title//'已审核重大任务.xlsx';
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }).catch(err =>{
        ElMessage.error('导出失败')
    });
}

function search() {
    query.value.page = 1;
    getList();
}
let tableColumns = computed(() => {
    let main = auditConfig[mainTab.value];
    return main;
});
const mainTabClick = (vm) => {
    mainTab.value = vm.props.name;
    checkData.value = []
    query.value = {
        page: 1,
        size: 15,
        getMyVerifiedAction: '',
        currentNodeId: '',
        actionName: '',
        indexName: '',
        getMyVerifiedIndex: '',
        actionDeptId: '',
    };
    getList();
};
function resize() {
    query.value = {
        page: 1,
        size: 15,
        getMyVerifiedAction: '',
        currentNodeId: '',
        actionName: '',
        indexName: '',
        getMyVerifiedIndex: '',
        actionDeptId: '',
    };
    actionDeptName.value = [];
    getList();
}

//获取subMenu权限
const subMenu = computed(() => {
    return store.state.user.sub_menu;
});
watch(
    route,
    (newValue, oldValue) => {
        // console.log(route,newValue, oldValue);
    },
    { deep: true, immediate: true }
);
// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
onMounted(() => {
    mainTab.value = route.query.tab || '1';
    getList();
});
onUnmounted(() => {});
</script>
<style lang="stylus" scoped>
.taskReview {
    height: calc(100% - 32px);
    header {
        height: 128px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .searchs {
            display: flex;
            justify-content: space-between;
        }
        .tabs {
            display: flex;
            height: 50px;
            padding-bottom: 12px;
        }
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 144px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .subToolbar {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
        }
        .title {
            display: flex;
            align-items: center;
        }
        .table {
            height: calc(100% - 92px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                height: 40px;
                padding-top: 10px;
                background: #fff;
                text-align: right;
                padding-right: 24px;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
        .status {
            display: inline-block;
            width: 68px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            &.status0 {
                background: rgba(68, 203, 87, 0.2);
                color: #44cd40;
            }
            &.status1 {
                background: rgba(255, 127, 127, 0.2);
                color: #ff5c5c;
            }
        }
    }
    // /deep/ .el-table {
    //     .cell {
    //         display: flex !important;
    //     }
    // }
    .check {
        margin-left: 5px;
    }
}
</style>
