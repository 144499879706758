export default {
    3: [
        {
            prop: 'actionDeptName',
            label: '牵头单位',
            width: '',
        },
        {
            prop: 'actionName',
            label: '重大任务',
            width: '',
        },
        {
            prop: 'indexName',
            label: '指标名称',
            width: '',
        },
        {
            prop: 'indexNum',
            label: '目标值',
            width: '',
            class: 'textCenter',
        },
        {
            prop: 'sourceName',
            label: '数源应用名称',
            width: '',
        },
        {
            prop: 'unitNameAndOrgNum',
            label: '责任单位',
            width: '',
        },
    ],
    2: [
        {
            prop: 'actionDeptName',
            label: '牵头单位',
            width: '',
        },
        {
            prop: 'actionName',
            label: '重大任务',
            width: '',
        },
        {
            prop: 'actionTarget',
            label: '目标体系',
            width: '',
        },
        {
            prop: 'actionWork',
            label: '工作体系',
            width: '',
        },
        {
            prop: 'actionPolicy',
            label: '政策体系',
            width: '',
        },
        {
            prop: 'actionEvaluation',
            label: '评价体系',
            width: '',
        },
        {
            prop: 'actionProgress',
            label: '进度安排',
            width: '',
        },
        {
            prop: 'actionJointDeptName',
            label: '协同单位',
            width: '',
        },
        {
            prop: 'indexNumber',
            label: '指标数量',
            width: '',
            class: 'textCenter',
        },
    ],
    1: [
        {
            prop: 'actionDeptName',
            label: '牵头单位',
            width: '',
        },
        {
            prop: 'actionName',
            label: '重大任务',
            width: '',
        },
        {
            prop: 'actionTarget',
            label: '目标体系',
            width: '',
        },
        {
            prop: 'actionWork',
            label: '工作体系',
            width: '',
        },
        {
            prop: 'actionPolicy',
            label: '政策体系',
            width: '',
        },
        {
            prop: 'actionEvaluation',
            label: '评价体系',
            width: '',
        },
        {
            prop: 'actionProgress',
            label: '进度安排',
            width: '',
        },
        {
            prop: 'indexNumber',
            label: '指标数量',
            width: '80',
            class: 'textCenter',
        },
        {
            prop: 'lastVerifyPersonName',
            label: '上一环节审核人',
            width: '',
        },
        {
            prop: 'lastVerifyTime',
            label: '审核时间',
            width: '',
        },
    ],
};
